import { FilterSection } from "./FilterSection";
import { useSelector } from "react-redux";
import { RootState } from "store";
import { Select } from "components/atoms/Select";
import { IconType } from "react-icons";

export const FilterTrackingSelection = ({
  title,
  value,
  onChange,
  icon,
}: {
  title: string;
  value: string;
  onChange(item: string): void;
  icon: IconType;
}): JSX.Element => {
  const { listOfTrackingStatuses } = useSelector((state: RootState) => {
    return state.shipmentsSlice;
  });

  const options = listOfTrackingStatuses.map((element, index) => {
    return { label: element, value: index + 1 };
  });
  const TrackingStatus =
    options.find((trackingStatus) => {
      return trackingStatus.label === value;
    }) || null;

  return (
    <FilterSection name={title} icon={icon}>
      <Select
        value={TrackingStatus}
        isNullable={true}
        onChange={(option) => {
          onChange(option?.label || null);
        }}
        placeholder="Select tracking status"
        optionTemplate={({ label }) => {
          return (
            <div className="flex items-center">
              <div>{label}</div>
            </div>
          );
        }}
        options={options}
      />
    </FilterSection>
  );
};
