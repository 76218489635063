import cx from "classnames";
import { Center } from "components/atoms/Center";
import { InternalLink } from "components/atoms/InternalLink";
import { Stack } from "components/atoms/Stack";
import { createElement, ReactNode } from "react";
import { ThemeColorPalettes } from "theme/theme-colors.theme";
import { formatNumberWithCommas } from "utils/formatNumberWithCommas";
import { IconType } from "react-icons/lib";
import styled, { useTheme } from "styled-components";

export type ITabbedNavigationItemProps = {
  children: ReactNode;
  color?: ThemeColorPalettes;
  activeColor?: ThemeColorPalettes;
  count?: number;
  isActive: boolean;
  icon: IconType;
  href: string;
  daysBetweenDates: number;
};

const StyledTabbedNavigationItem = styled.div<{
  isActive: boolean;
  activeColor: ThemeColorPalettes;
  color: ThemeColorPalettes;
}>`
  flex: 1;
  display: block;
  border-radius: 10px 10px 0 0;
  padding-top: ${(props) => props.theme.space[3]};
  padding-bottom: ${(props) => props.theme.space[3]};
  padding-left: ${(props) => props.theme.gutter};
  padding-right: ${(props) => props.theme.gutter};
  border: 2px solid;
  position: relative;
  border-color: ${(props) => props.theme.borderColor};
  border-color: ${({ theme, isActive, color }) =>
    isActive ? theme.colors[color][10] : theme.colors.gray[6]};

  ${({ theme, isActive, activeColor }) =>
    isActive
      ? "margin-bottom: -2px; border-bottom-color: transparent;"
      : `border-bottom: none; box-shadow: 0 2px 0 0
      ${theme.colors[activeColor][10]};`}

  background: ${({ theme, isActive }) =>
    isActive ? theme.backgroundColor : theme.colors.gray[3]};
`;

export const TabbedNavigationItem = (props: ITabbedNavigationItemProps) => {
  const {
    children,
    isActive,
    href,
    color,
    count,
    icon,
    activeColor,
    daysBetweenDates,
  } = props;

  const theme = useTheme();

  return (
    <InternalLink className="block" href={href}>
      <StyledTabbedNavigationItem
        isActive={isActive}
        color={color}
        activeColor={activeColor}
      >
        <div className="flex items-center">
          <div
            className={cx("mr-3 rounded-md text-xl border")}
            style={{
              width: 50,
              height: 50,
              borderColor: isActive
                ? theme.colors[color][5]
                : theme.colors.gray[5],
              backgroundColor: isActive
                ? theme.colors[color][3]
                : "transparent",
              color: theme.colors[color][9],
            }}
          >
            <Center>{createElement(icon)}</Center>
          </div>
          <div className="w-full">
            <div className="flex items-center mb-2">
              <div
                className={cx(
                  "text-sm tracking-wide font-semibold leading-none uppercase",
                  {
                    "text-muted": !isActive,
                  }
                )}
              >
                {children}
              </div>
            </div>
            <div className="flex flex-row">
              <div
                className={cx(
                  "leading-none text-xl font-semibold flex items-end mr-2"
                )}
                style={{
                  color: theme.colors[color][9],
                }}
              >
                {formatNumberWithCommas(count)}
              </div>
              <div
                className={"leading-none text-sm font-medium flex items-end"}
                style={{
                  color: theme.colors["gray"][9],
                  marginBottom: 1,
                }}
              >
                {daysBetweenDates ? daysBetweenDates : "Today"}{" "}
                {daysBetweenDates
                  ? daysBetweenDates > 1
                    ? "days"
                    : "day"
                  : ""}
              </div>
            </div>
          </div>
        </div>
      </StyledTabbedNavigationItem>
    </InternalLink>
  );
};

export const TabbedNavigation = ({
  children,
  activeColor,
}: {
  children: React.ReactNode;
  activeColor;
}) => {
  const theme = useTheme();

  return (
    <Stack
      direction="horizontal"
      className="relative flex items-center flex-shrink-0 w-full px-5 pt-2 border-b-2"
      childClassName="flex-1 top-0 relative"
      spacing={2}
      style={{
        borderColor: theme.colors[activeColor][10],
        backgroundColor: theme.colors.gray[4],
      }}
    >
      {children}
    </Stack>
  );
};
